import type { ReactElement, ReactNode } from "react";
import "./Spaced.css";
import classNames from "classnames";

type Spacing = "0" | "px" | "1" | "2" | "3" | "4" | "5" | "6" | "8";

export interface SpacedProps {
  spacing?: Spacing;
  className?: string;
  children: ReactNode;
}

export default function Spaced(props: SpacedProps): ReactElement {
  const { spacing = "4", className, children } = props;

  const fullClassName = classNames(`spaced-${spacing}`, className);

  return <div className={fullClassName}>{children}</div>;
}
