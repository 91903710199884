import classNames from "classnames";
import type { ReactElement, ReactNode } from "react";
import { useMemo } from "react";
import "./Button.css";

export type ButtonVariant = "primary" | "secondary";

export type ButtonWidth = "auto" | "full";

export interface ButtonProps {
  variant?: ButtonVariant;
  width?: ButtonWidth;
  disabled?: boolean;
  className?: string;
  children: ReactNode;
}

export const buttonVariantClasses: Record<ButtonVariant, string> = {
  primary: "button-primary",
  secondary: "button-secondary",
};

export const buttonWidthClasses: Record<ButtonWidth, string> = {
  full: "button-width-full",
  auto: "button-width-auto",
};

export function Button(props: ButtonProps): ReactElement {
  const {
    variant = "primary",
    width = "auto",
    disabled = false,
    className: _className,
    children,
  } = props;

  const className = useMemo(
    () =>
      classNames(
        buttonVariantClasses[variant],
        buttonWidthClasses[width],
        disabled ? "button-disabled" : null,
        _className
      ),
    [_className, disabled, variant, width]
  );

  return (
    <button type="button" disabled={disabled} className={className}>
      {children}
    </button>
  );
}
