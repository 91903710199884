import { checkExhausted } from "@cartographerio/util";
import classNames from "classnames";
import { useMemo, type ReactElement, type ReactNode } from "react";

export interface ContainerProps {
  width: "default" | "narrow" | "wide" | "full";
  className?: string;
  children: ReactNode;
}

export function Container(props: ContainerProps): ReactElement {
  const { width, className: _className, children } = props;

  const maxWidth = useMemo(() => {
    switch (width) {
      case "narrow":
        return "50ch";
      case "default":
        return "60ch";
      case "wide":
        return "80ch";
      case "full":
        return "100vw";
      default:
        return checkExhausted(width);
    }
  }, [width]);

  const className = useMemo(
    () => classNames("container mx-auto px-8", _className),
    [_className]
  );

  return (
    <div className={className} style={{ maxWidth }}>
      {children}
    </div>
  );
}
